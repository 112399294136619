import EventBus from '@/services/event.bus';

class MainNewsStripServices {
  async pollData(): Promise<void> {
    EventBus.$emit('updateNews');
    setInterval(async () => {
      console.info('JnMainNewsStrip.pollData: updating news feed...');
      EventBus.$emit('updateNews');
    }, Number(process.env.VUE_APP_DATA_FEED_REFRESH_RATE));
  }
}

export const JnMainNewsStripServices = new MainNewsStripServices();
