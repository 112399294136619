
import { Component, Vue } from "vue-property-decorator";
import JnService from "@/views/home/ServiceCard.vue";
import { Service } from "@/models/service.model";
import { SearchData } from "@/models/search.model";
import { EsService } from "@/services/es.service";

@Component({
  components: { JnService },
})
export default class JnHomeQuickAccessStrip extends Vue {
  services: Service[] = [];

  async beforeCreate() {
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES!,
      size: 10,
      from: 0,
    });
    // this.services = await EsService.getServicesInServicesStrip(searchData);
  }
}
