
import { Component, Vue } from 'vue-property-decorator';
import JnNews from './NewsCard.vue';
import { Document } from '@/models/document.model';

@Component({
  components: { JnNews },
})
export default class JnHomeSecondStrip extends Vue {
  news: Document[] = [];

  goToFormRichiestaProva() {
    this.$router.push('/richiesta-trial');
  }
  goToFormSottoscrizioneNewsletter() {
    this.$router.push('/sottoscrizione-newsletter');
  }
}
