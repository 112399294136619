export class CarouselGroup {

  id: number
  from: number

  constructor(
    id: number,
    from: number
  ) {
    this.id = id
    this.from = from
  }

}
