
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { EsService } from '@/services/es.service';
import JnNewsMicro from './NewsMicroCard.vue';
import { CarouselGroup } from '@/models/carousel-group.model';
import moment from 'moment';
import { Categories } from '@/models/enums.model';

@Component({
  components: { JnNewsMicro },
})
export default class JnNewsMicroGroup extends Vue {
  @Prop({ required: true, default: '' })
  props!: CarouselGroup;

  news: Document[] = [];

  async mounted() {
    const toTime = moment().format('YYYY-MM-DD');
    const fromTime = moment(toTime, 'YYYY-MM-DD')
      .subtract(process.env.VUE_APP_HOME_VERTICAL_NEWS_NUM_DAYS, 'days')
      .format('YYYY-MM-DD');
    const searchData = new SearchData({
      index: process.env.VUE_APP_JURANEWS_INDEXES!,
      from: this.props.from,
      size: Number(process.env.VUE_APP_HOME_VERTICAL_NEWS_SIZE),
      since: fromTime,
      to: toTime,
      idCategory: [Categories.ULTIME_NEWS],
    });
    this.news = await EsService.getDocs(searchData);
  }
}
