
import { Component, Vue } from 'vue-property-decorator';
import JnDoc from '@/views/common/DocCard.vue';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { Categories } from '@/models/enums.model';
import { EsService } from '@/services/es.service';
import { Pagination } from '@/models/pagination.model';
import EventBus from '@/services/event.bus';
import { JnHomeConcorsiStripServices } from './HomeConcorsiStrip';

@Component({
  components: { JnDoc },
})
export default class JnConcorsiStrip extends Vue {
  pagination?: Pagination;

  allDocs: Document[] = [];
  shownDocs: Document[] = [];

  async mounted() {
    EventBus.$on('updateConcorsi', async () => {
      const searchData = new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES!,
        from: 0,
        idCategory: [Categories.CONCORSI],
      });
      this.allDocs = await EsService.getDocs(searchData);
      this.pagination = new Pagination({
        page: 1,
        total: this.allDocs.length,
        perPage: 4,
        visible: 6,
      });

      this.show(0, this.pagination.perPage);
    });

    await JnHomeConcorsiStripServices.pollData();
  }

  next() {
    if (this.pagination) {
      const from = (this.pagination.page - 1) * this.pagination.perPage;
      const size = this.pagination.perPage;
      this.show(from, size);
    }
  }

  show(from: number, size: number) {
    this.shownDocs = [];

    for (let i = from; i < from + size; i++) {
      if (this.allDocs[i]) {
        this.shownDocs.push(this.allDocs[i]);
      }
    }
  }
}
