import EventBus from '@/services/event.bus';

class HomeConcorsiStripServices {
  async pollData(): Promise<void> {
    EventBus.$emit('updateConcorsi');
    setInterval(async () => {
      console.info('JnHomeConcorsiStrip.pollData: updating concorsi feed...');
      EventBus.$emit('updateConcorsi');
    }, Number(process.env.VUE_APP_DATA_FEED_REFRESH_RATE));
  }
}

export const JnHomeConcorsiStripServices = new HomeConcorsiStripServices();
