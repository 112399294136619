
import { Component, Vue, Prop } from "vue-property-decorator";
import JnDoc from "@/views/common/DocCard.vue";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { Categories } from "@/models/enums.model";
import { EsService } from "@/services/es.service";
import { Pagination } from "@/models/pagination.model";
import { JnHomeTagsStripService } from "./HomeTagStrip";
import EventBus from "@/services/event.bus";

@Component({
  components: { JnDoc },
})
export default class JnHomeTagStrip extends Vue {
  @Prop({ required: true })
  tag?: string;

  @Prop({ required: true })
  title?: string;

  pagination?: Pagination;

  allDocs: Document[] = [];
  shownDocs: Document[] = [];

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    size: 20,
    from: 0,
    tags: [this.tag!],
    withText: true,
  });

  async mounted() {
    EventBus.$on("updateHomeTags", async (mainNews: Document[]) => {
      this.allDocs = await EsService.getDocs(this.searchData);
      this.pagination = new Pagination({
        page: 1,
        total: this.allDocs.length,
        perPage: 4,
        visible: 6,
      });

      this.show(0, this.pagination.perPage);
    });

    await JnHomeTagsStripService.pollData();
  }

  next() {
    if (this.pagination) {
      const from = (this.pagination.page - 1) * this.pagination.perPage;
      const size = this.pagination.perPage;
      this.show(from, size);
    }
  }

  show(from: number, size: number) {
    this.shownDocs = [];

    for (let i = from; i < from + size; i++) {
      if (this.allDocs[i]) {
        this.shownDocs.push(this.allDocs[i]);
      }
    }
  }
}
