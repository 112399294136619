
import { Component, Vue } from "vue-property-decorator";
import JnNewsMicroGroup from "./NewsMicroGroup.vue";
import { Document } from "@/models/document.model";
import { SearchData } from "@/models/search.model";
import { CarouselGroup } from "@/models/carousel-group.model";
import { EsService } from "@/services/es.service";
import { JnHomeMainStripServices } from "./HomeMainStrip";
import moment from "moment";
import { Categories } from "@/models/enums.model";
import EventBus from "@/services/event.bus";

@Component({
  components: { JnNewsMicroGroup },
})
export default class JnHomeMainStrip extends Vue {
  mainNews: Document[] = [];
  microNewsGroupParams: CarouselGroup[] = [];
  currentNewsId!: number;

  view() {
    this.$router.push("/docs/juranews/" + this.currentNewsId);
  }

  async beforeCreate() {
    EventBus.$on("updateMainNews", async () => {
      /*
       * MAIN NEWS: recupera le news del giorno
       */
      const searchDataMain = new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES!,
        from: 0,
        size: Number(process.env.VUE_APP_HOME_MAIN_NEWS_SIZE),
        idCategory: [Categories.ULTIME_NEWS],
      });
      this.mainNews = await EsService.getDocs(searchDataMain);
    });
    EventBus.$on("updateLastNews", async () => {
      /*
       * VERTICAL NEWS: recupera le news della settimana
       */
      const toTime = moment().format("YYYY-MM-DD");
      const fromTime = moment(toTime, "YYYY-MM-DD")
        .subtract(process.env.VUE_APP_HOME_VERTICAL_NEWS_NUM_DAYS, "days")
        .format("YYYY-MM-DD");
      const searchDataVertical = new SearchData({
        index: process.env.VUE_APP_JURANEWS_INDEXES!,
        from: 0,
        since: fromTime,
        to: toTime,
        idCategory: [Categories.ULTIME_NEWS],
      });
      const count = await EsService.countDocs(searchDataVertical);
      let id = 0;
      this.microNewsGroupParams = [];
      for (
        let i = 0;
        i < count;
        i = i + Number(process.env.VUE_APP_HOME_VERTICAL_NEWS_SIZE)!
      ) {
        this.microNewsGroupParams.push(new CarouselGroup(id, i));
        id = id + 1;
      }
    });

    await JnHomeMainStripServices.pollData();
  }
}
