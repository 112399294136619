import EventBus from '@/services/event.bus';

class HomeMainStripServices {
  async pollData(): Promise<void> {
    EventBus.$emit('updateMainNews');
    EventBus.$emit('updateLastNews');
    setInterval(async () => {
      console.info(
        'JnHomeMainStrip.pollData: updating main and last news feed...'
      );
      EventBus.$emit('updateMainNews');
      EventBus.$emit('updateLastNews');
    }, Number(process.env.VUE_APP_DATA_FEED_REFRESH_RATE));
  }
}

export const JnHomeMainStripServices = new HomeMainStripServices();
