<template>
  <cookie-law theme="dark-lime" buttonText="Accetto">
    <div slot="message">
      Questo sito utilizza cookie tecnici al fine di garantire una migliore
      esperienza d'uso, in accordo con questa
      <router-link to="/policies/cookie" v-slot="{ href, navigate }">
        <span
          :href="href"
          @click="navigate"
          style="cursor: pointer"
          class="red--text-juranews"
        >
        policy
        </span>
      </router-link>
    </div>
  </cookie-law>
</template>

<script lang="js">
import { Component, Prop, Vue } from "vue-property-decorator";
import CookieLaw from 'vue-cookie-law';

@Component({
  components: { CookieLaw },
})
export default class JnCookieBanner extends Vue {

}
</script>
