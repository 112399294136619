import { Component, Vue } from 'vue-property-decorator';
import JnNewsMicroGroup from './NewsMicroGroup.vue';
import { Document } from '@/models/document.model';
import { SearchData } from '@/models/search.model';
import { CarouselGroup } from '@/models/carousel-group.model';
import { EsService } from '@/services/es.service';
import moment from 'moment';
import { Categories } from '@/models/enums.model';
import EventBus from '@/services/event.bus';

class HomeTagsStripServices {
  async pollData(): Promise<void> {
    EventBus.$emit('updateHomeTags');
    setInterval(async () => {
      console.info(
        'JnHomeTagsStripServices.pollData: updating home tags feed...'
      );
      EventBus.$emit('updateHomeTags');
    }, Number(process.env.VUE_APP_DATA_FEED_REFRESH_RATE));
  }
}

export const JnHomeTagsStripService = new HomeTagsStripServices();
