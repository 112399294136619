
import { Component, Vue, Prop } from 'vue-property-decorator';
import { SearchData } from '@/models/search.model';
import { EsService } from '@/services/es.service';
import { CarouselGroup } from '@/models/carousel-group.model';
import { Categories, CategoriesNews } from '@/models/enums.model';
import { Pagination } from '@/models/pagination.model';
import { Document } from '@/models/document.model';
import JnDoc from '@/views/common/DocCard.vue';
import { JnMainNewsStripServices } from './MainNewsStrip';
import EventBus from '@/services/event.bus';

@Component({
  components: { JnDoc },
})
export default class JnMainNewsStrip extends Vue {
  @Prop({ required: true })
  rows?: number;

  mainNewsGroupParams: CarouselGroup[] = [];

  pagination?: Pagination;

  allDocs: Document[] = [];
  shownDocs: Document[] = [];

  searchData = new SearchData({
    index: process.env.VUE_APP_JURANEWS_INDEXES!,
    from: 0,
    featured: 1,
    idCategory: CategoriesNews,
    withText: true,
  });

  async mounted() {
    EventBus.$on('updateNews', async () => {
      this.allDocs = await EsService.getDocs(this.searchData);
      this.pagination = new Pagination({
        page: 1,
        total: this.allDocs.length,
        perPage: this.rows! * 2,
        visible: 6,
      });
      this.show(0, this.pagination.perPage);
    });
    await JnMainNewsStripServices.pollData();
  }

  next() {
    if (this.pagination) {
      const from = (this.pagination.page - 1) * this.pagination.perPage;
      const size = this.pagination.perPage;
      this.show(from, size);
    }
  }

  show(from: number, size: number) {
    this.shownDocs = [];
    for (let i = from; i < from + size; i++) {
      if (this.allDocs[i]) {
        this.shownDocs.push(this.allDocs[i]);
      }
    }
  }
}
