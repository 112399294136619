
import { Component, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class JnHomeRegistrazioneStrip extends Vue {
  goToRegistrazione() {
    this.$router.push('/abbonamenti');
  }
}
